import React from "react";
import { extent, max } from "d3-array";

export default function useDataDomains(data, visualisation, secondaryData) {
    const domains = React.useMemo(() => {
        let dataExtent = extent(
            data.filter(d => d.value !== null),
            (d, i) => {
                if (secondaryData) {
                    return d.value + secondaryData[i].value;
                }
                return d.value;
            }
        );
        const breakpointMax = visualisation.breakpoints ? max(visualisation.breakpoints, d => d.value) : 0;

        return {
            data: [Math.min(dataExtent[0], 0), Math.max(dataExtent[1], breakpointMax * 1.1)],
            x: data.map(d => d.date)
        };
    }, [data, visualisation, secondaryData]);

    return domains;
}
