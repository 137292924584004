/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:6e4be730-93e3-4ffc-84bb-1ed0af309e82",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_8QWEI57Wi",
    "aws_user_pools_web_client_id": "21n14fjlghpveq9hmilhj9hqao",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "covidSeriesAPI",
            "endpoint": "https://rvrusb6mha.execute-api.ap-southeast-2.amazonaws.com/master",
            "region": "ap-southeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "dpddatasets-master",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
