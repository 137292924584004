import React from "react";
import { useSelector } from "react-redux";
import VISUALISATION_CONFIG from "../../config/visualisation-config";
import { selectPolicyInvokedData, selectData, selectHistoricalData } from "../../slices/data.slice";
import VisFactoid from "../vis-factoid/vis-factoid";
import styles from "./vis-list.module.scss";
import useComponentSize from "../../modules/hooks/use-component-size";
import { selectHoveredDate } from "../../slices/hover.slice";

const VisList = () => {
    const { activeCases, bedsCritical, hospitalizedNonCritical, rateOfChange, recovered } = VISUALISATION_CONFIG;

    // TODO: add to config
    const visualisations = (
        <>
            <VisContainer visualisation={activeCases} grid={{ column: "1 / 7", row: "1 / 5" }} />
            <VisContainer visualisation={bedsCritical} grid={{ column: "7 / 10", row: "1 / 3" }} />
            <VisContainer visualisation={hospitalizedNonCritical} grid={{ column: "7 / 10", row: "3 / 5" }} />
            <VisContainer visualisation={rateOfChange} grid={{ column: "1 / 7", row: "5 / 7" }} />
            <VisContainer visualisation={recovered} grid={{ column: "7 / 10", row: "5 / 7" }} />
        </>
    );

    return <div className={styles.visList}>{visualisations}</div>;
};

const VisContainer = (props) => {
    const { visualisation, grid } = props;

    const visRef = React.useRef(null);
    const dimensions = useComponentSize(visRef);

    const allData = useSelector(selectData);
    const historicalData = useSelector(selectHistoricalData);
    const visData = React.useMemo(() => {
        return allData[visualisation.id] || null;
    }, [visualisation, allData]);
    const secondaryData = React.useMemo(() => {
        return visualisation.secondaryData ? allData[visualisation.secondaryData] || null : null;
    }, [visualisation, allData]);

    const visHistoricalData = React.useMemo(() => {
        return historicalData[visualisation.id] || null;
    }, [visualisation, historicalData]);
    const secondaryHistoricalData = React.useMemo(() => {
        return visualisation.secondaryData ? historicalData[visualisation.secondaryData] || null : null;
    }, [visualisation, historicalData]);

    const policyInvokedData = useSelector(selectPolicyInvokedData);
    const hoveredDate = useSelector(selectHoveredDate);

    const VisComponent = visualisation.visualisation;

    return (
        <div
            className={styles.visItem}
            style={{
                gridColumn: grid.column,
                gridRow: grid.row,
            }}
        >
            <div className={styles.visComponents}>
                {visData && (
                    <VisFactoid
                        data={visData}
                        secondaryData={secondaryData}
                        visualisation={visualisation}
                        hoveredDate={hoveredDate}
                    />
                )}
                <div className={styles.visChartArea} ref={visRef}>
                    {dimensions && visData && (
                        <VisComponent
                            data={visData}
                            secondaryData={secondaryData}
                            historicalData={visHistoricalData}
                            secondaryHistoricalData={secondaryHistoricalData}
                            policyInvokedData={policyInvokedData}
                            visualisation={visualisation}
                            dimensions={dimensions}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default VisList;
