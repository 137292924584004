const POLICIES = [
    {
        label: "Normal",
        value: "normal",
        r0: 2.4
    },
    {
        label: "Level 1",
        description: "Social Distancing",
        value: "level1",
        r0: 2.0
    },
    {
        label: "Level 2",
        description: "Mild Shutdown",
        value: "level2",
        r0: 1.5
    },
    {
        label: "Level 3",
        description: "Essential Services",
        value: "level3",
        r0: 1.05
    },
    {
        label: "Level 4",
        description: "Strong Shutdown",
        value: "level4",
        r0: 0.9
    },
    {
        label: "Level 5",
        description: "Absolute Shutdown",
        value: "level5",
        r0: 0.5
    }
];

export default POLICIES;
