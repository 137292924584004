import { createSlice } from "@reduxjs/toolkit";
import { addDays, startOfDay, parse as parseDate } from "date-fns";
import VISUALISATION_CONFIG from "../config/visualisation-config";
import { HISTORY_LENGTH } from "../config/timespan-config";
import { API } from "aws-amplify";

// -------------------------------------------------------------
// Slice
export const slice = createSlice({
  name: "data",
  initialState: {
    historicalData: {},
    data: {},
    policyInvokedData: [],
    loading: true,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setHistoricalData: (state, action) => {
      state.historicalData = action.payload;
    },
    setPolicyInvokedData: (state, action) => {
      state.policyInvokedData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setData,
  setHistoricalData,
  setLoading,
  setPolicyInvokedData,
} = slice.actions;

// -------------------------------------------------------------
// Thunks

const extractVisData = (visId, parsed, calculateRowValue, settings, historicDataLength) => {
  const today = startOfDay(new Date());
  const iterable = calculateRowValue
    ? parsed[Object.keys(parsed)[0]]
    : parsed[visId];
  // exclude the backward estimations
  const visData = iterable.map((rawValue, index) => {
    const date = addDays(today, index - HISTORY_LENGTH).getTime();
    let value = calculateRowValue
      ? calculateRowValue(parsed, index, settings)
      : parseFloat(rawValue);
    return {
      index,
      date,
      value,
    };
  }).map((el, index) => {
      let {value} = el;
      if(index < historicDataLength) {
        value = 0;
      }

      return {...el, value};
  });

  return visData;
};

const extractHistoryData = (dataObj) => {
  let visData = Object.entries(dataObj).map((kvp, index) => {
    const [dateStr, rawValue] = kvp;
    const dateParsed = parseDate(dateStr, "d/MM/yyyy", new Date());
    const date = startOfDay(dateParsed).getTime();
    const value = parseFloat(rawValue);
    return {
      index: index,
      date,
      value,
    };
  });

  return visData;
};

const segmentPolicyData = (policyData) => {
  let segments = [];
  let acc = [];

  policyData.forEach((value, index) => {
    const previousTrue = index === 0 || policyData[index - 1] === 1;

    // If the previous and this one are true, continue the segment
    if (previousTrue && value) {
      acc.push(index);
    } else {
      if (acc.length > 0) {
        segments.push(acc);
        acc = [];
      }
    }
  });

  if (acc.length > 0) {
    segments.push(acc);
  }

  return segments.map((segment) => ({
    start: segment[0],
    end: segment[segment.length - 1],
  }));
};

export const updateData = (settings) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const historicalSettings = { location_key: settings.location_key };

    const historicalQueryParams = Object.entries(historicalSettings)
      .filter(([key, value]) => value !== null)
      .reduce((result, [key, value]) => ({ ...result, [key]: value }), {});

    const historicalRawData = await API.get(
      "covidSeriesAPI",
      "/covid-series-historical",
      {
        // OPTIONAL
        headers: {
          "Content-Type": "application/json",
        },
        // response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        queryStringParameters: historicalQueryParams,
        
      }
    );
    // const historicalUrl = `https://api.pwp.faethm.ai/v1/covid-series-historical/?${queryString}`;
    // const historicalUrl = `/data/${settings.historicalDataFilename}`;
    // const historyRes = await fetch(historicalUrl, {
    //     headers: {
    //         "x-api-key": process.env.REACT_APP_API_KEY,
    //     },
    // });
    // const historicalRawData = await historyRes.json();
    // const historicalRawText = await historyRes.text();
    // const historicalRawDataRows = csvParse(historicalRawText);

    // const historicalRawData = historicalRawDataRows.reduce(
    //     (acc, n) => {
    //         const date = n.Date;
    //         const newly = n.New;
    //         const active = n.Total;

    //         const newVals = { ...acc };

    //         newVals.active_cases[date] = active;
    //         newVals.newly_infected[date] = newly;

    //         return newVals;
    //     },
    //     {
    //         active_cases: {},
    //         newly_infected: {},
    //     }
    // );

    let historicalData = {};
    Object.values(VISUALISATION_CONFIG).forEach((vis) => {
      // Prepend the historical data.
      if (historicalRawData[vis.id])
        historicalData[vis.id] = extractHistoryData(historicalRawData[vis.id]);

      if (vis.secondaryData && historicalRawData[vis.secondaryData]) {
        historicalData[vis.secondaryData] = extractHistoryData(
          historicalRawData[vis.secondaryData]
        );
      }
    });
    const numberOfProvidedHistory = Object.values(historicalData["newly_infected"]).length;
    const startingNewlyInfected = Math.round((
      Object.values(historicalData["newly_infected"])[numberOfProvidedHistory-2].value
    + Object.values(historicalData["newly_infected"])[numberOfProvidedHistory-1].value
    )/2);

    const { latentCases } = settings;
    const settingsWithInserts = {
      ...settings,
      starting_dead: historicalRawData.deaths,
      actual_starting_newly_infected: latentCases
        ? null
        : startingNewlyInfected,
      reported_newly_infected: latentCases ? startingNewlyInfected : null,
      historicalDataFilename: null,
      latentCases: null,
    };

    const queryParams = Object.entries(settingsWithInserts)
      .filter(([key, value]) => value !== null)
      .reduce((result, [key, value]) => ({ ...result, [key]: value }), {});

    const parsed = await API.get("covidSeriesAPI", "/covid-series", {
      // OPTIONAL
      headers: {
        "Content-Type": "application/json",
      },
      // response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: queryParams,
    });

    let outData = {};

    Object.values(VISUALISATION_CONFIG).forEach((vis) => {
      // Prepend the historical data.
      outData[vis.id] = extractVisData(
        vis.id,
        parsed,
        vis.calculateRowValue,
        settings,
        numberOfProvidedHistory
      );

      if (vis.secondaryData) {
        outData[vis.secondaryData] = extractVisData(
          vis.secondaryData,
          parsed,
          null,
          settings,
          numberOfProvidedHistory
        );
      }
      // adjusting red breakpoint for icu chart
      if(vis.id === "required_icu_beds"){
        vis.breakpoints.find(v => v.label === "ICU Beds").value = settings.icu_beds_supply;
      }
    });

    dispatch(setData(outData));
    dispatch(setHistoricalData(historicalData));

    // Create policy active segments
    const policySegments = segmentPolicyData(parsed["policy_invoked"]);
    dispatch(setPolicyInvokedData(policySegments));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(setLoading(false));
  }
};

// -------------------------------------------------------------
// Selectors
export const selectData = (state) => state.data.data;
export const selectHistoricalData = (state) => state.data.historicalData;
export const selectLoading = (state) => state.data.loading;
export const selectPolicyInvokedData = (state) => state.data.policyInvokedData;

export default slice.reducer;
