import React from "react";
import styles from "./bar-chart.module.scss";
import useDataDomains from "../../modules/hooks/use-data-domains";
import { scaleBand, scaleLinear } from "d3-scale";
import { area, line } from "d3-shape";
import { useDispatch, useSelector } from "react-redux";
import { setHoveredDate, selectHoveredDate } from "../../slices/hover.slice";
import { selectTimespan } from "../../slices/settings.slice";
import { format as formatDate } from "date-fns";
import { HISTORY_LENGTH } from "../../config/timespan-config";

const PADDING = {
    top: 16,
    left: 36,
    bottom: 24,
    right: 0,
};

const ENABLE_ANIMATION = true;
const TIMESPAN_USE_AREA = 180;

const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

function useScales(dimensions, domains) {
    const targetYMax = React.useMemo(() => domains.data[1], [domains]);
    const [currentYMax, setCurrentYMax] = React.useState(domains.data[1]);
    const existingAnimationMax = React.useRef(null);

    React.useEffect(() => {
        if (!ENABLE_ANIMATION) {
            setCurrentYMax(targetYMax);
            return;
        }

        // If animating, cancel.
        if (existingAnimationMax.current) {
            cancelAnimationFrame(existingAnimationMax.current);
            existingAnimationMax.current = null;
        }
        const newTarget = targetYMax;
        const diff = newTarget - currentYMax;

        if (diff === 0) return;

        let val = 0;
        const tick = () => {
            val += 3 / 60;

            const easedVal = easeInOutQuad(val);

            if (val < 1.0) {
                const newYMax = currentYMax + diff * easedVal;
                setCurrentYMax(newYMax);
                existingAnimationMax.current = requestAnimationFrame(tick);
            } else {
                setCurrentYMax(targetYMax);
            }
        };
        existingAnimationMax.current = requestAnimationFrame(tick);

        return () => {
            cancelAnimationFrame(existingAnimationMax.current);
            existingAnimationMax.current = null;
        };
        // Intentional: we only want to call this when the target changes. adding currentYMax would create a loop.
        // eslint-disable-next-line
    }, [targetYMax]);

    return React.useMemo(() => {
        const frame = {
            top: 0 + PADDING.top,
            right: dimensions.width - PADDING.right,
            bottom: dimensions.height - PADDING.bottom,
            left: 0 + PADDING.left,
            width: dimensions.width - PADDING.right - PADDING.left,
            height: dimensions.height - PADDING.top - PADDING.bottom,
        };
        const x = scaleBand().range([frame.left, frame.right]).domain(domains.x);
        const y = scaleLinear().range([frame.bottom, frame.top]).domain([domains.data[0], currentYMax]);

        return {
            x,
            y,
            frame,
        };
    }, [dimensions, domains, currentYMax]);
}

const BarChart = (props) => {
    const {
        data,
        dimensions,
        visualisation,
        secondaryData,
        policyInvokedData,
        historicalData,
        secondaryHistoricalData,
    } = props;

    const domains = useDataDomains(data, visualisation, secondaryData);
    const scales = useScales(dimensions, domains);

    const handDownProps = React.useMemo(
        () => ({
            data,
            secondaryData,
            domains,
            scales,
            visualisation,
            policyInvokedData,
            historicalData,
            secondaryHistoricalData,
        }),
        [
            data,
            domains,
            scales,
            visualisation,
            secondaryData,
            policyInvokedData,
            historicalData,
            secondaryHistoricalData,
        ]
    );

    return (
        <svg className={styles.barChart}>
            <AxisX {...handDownProps} />
            <AxisY {...handDownProps} />
            <BarData {...handDownProps} />
            <AreaData {...handDownProps} />
            {(historicalData || secondaryHistoricalData) && <HistoricalDataLines {...handDownProps} />}
            <HistoricalHighlight {...handDownProps} />
            <PolicyHighlight {...handDownProps} />
            <ZeroLine {...handDownProps} />
            <BreakPoints {...handDownProps} />
            <HoverOverlay {...handDownProps} />
        </svg>
    );
};

const HistoricalHighlight = (props) => {
    const { scales } = props;
    const right = scales.x(scales.x.domain()[HISTORY_LENGTH]);
    const width = right - scales.frame.left;
    return (
        <g>
            <rect
                className={styles.historicalHighlight}
                x={scales.frame.left}
                y={scales.frame.top}
                width={width}
                height={scales.frame.height}
            />
            <line
                className={styles.historicalLine}
                x1={right}
                x2={right}
                y1={scales.frame.top}
                y2={scales.frame.bottom}
            />
            <text className={styles.todayLabel} x={right} y={9}>
                Today
            </text>
        </g>
    );
};

const ZeroLine = (props) => {
    const { domains, scales } = props;
    if (domains.data[0] >= 0) return null;
    return (
        <line
            className={styles.zeroLine}
            x1={scales.frame.left}
            x2={scales.frame.right}
            y1={scales.y(0)}
            y2={scales.y(0)}
        />
    );
};

const AxisX = (props) => {
    const { scales } = props;

    const hoveredDate = useSelector(selectHoveredDate);

    const tickMod = Math.floor(scales.x.domain().length / 10);
    const xTickData = scales.x.domain().filter((d, i) => i % tickMod === 0 || i === 0);

    const xTicks = xTickData
        .filter((d) => d)
        .map((tick) => {
            const x = scales.x(tick);
            return (
                <g key={tick} transform={`translate(${x} ${scales.frame.bottom + 4})`}>
                    <line className={styles.axisTickXLine} y1={-4} y2={0} />
                    <text className={styles.axisTickXText} transform="translate(-4 12)">
                        {formatDate(new Date(tick), "dd MMM")}
                    </text>
                </g>
            );
        });

    let hoveredDateEl = null;
    if (hoveredDate) {
        const hoverX = scales.x(hoveredDate);
        const hoverWidth = 60;
        hoveredDateEl = (
            <g>
                <g transform={`translate(${hoverX} ${scales.frame.bottom})`}>
                    <rect
                        className={styles.dateHoverRect}
                        x={-(hoverWidth / 2)}
                        width={hoverWidth}
                        height={24}
                        rx={4}
                        ry={4}
                    />
                    <text y={18} className={styles.dateHoverText}>
                        {formatDate(hoveredDate, "dd MMM")}
                    </text>
                </g>
                <line x1={hoverX} x2={hoverX} y1={scales.frame.top} y2={scales.frame.bottom} fill="white" />
            </g>
        );
    }

    return (
        <g>
            <line
                className={styles.axisLine}
                x1={scales.frame.left}
                x2={scales.frame.right}
                y1={scales.frame.bottom}
                y2={scales.frame.bottom}
            />
            <g>{xTicks}</g>
            {hoveredDateEl}
        </g>
    );
};

const GRID_LINES = [
    1_000,
    5_000,
    10_000,
    50_000,
    100_000,
    500_000,
    1_000_000,
    5_000_000,
    10_000_000,
    50_000_000,
    100_000_000,
];

const AxisY = (props) => {
    const { scales, visualisation } = props;

    const yTickData = scales.y.ticks(5);

    const yTicks = yTickData.map((tick, i) => {
        const y = scales.y(tick) + 3;

        if (visualisation.formatAxis(tick) === "Active" && i !== yTickData.length - 1) return null;

        return (
            <g key={tick} transform={`translate(${scales.frame.left} ${y})`}>
                <text className={styles.axisTickYText} transform="translate(-8 0)">
                    {visualisation.formatAxis(tick)}
                </text>
            </g>
        );
    });

    const gridLines = GRID_LINES.filter((d) => d < scales.y.domain()[1]).map((tick) => {
        const y = scales.y(tick);

        return (
            <line
                className={styles.gridLineY}
                key={tick}
                x1={scales.frame.left}
                x2={scales.frame.right}
                y1={y}
                y2={y}
            />
        );
    });

    return (
        <g>
            <line
                className={styles.axisLine}
                x1={scales.frame.left}
                x2={scales.frame.left}
                y1={scales.frame.bottom}
                y2={scales.frame.top}
            />
            <g>{yTicks}</g>
            <g>{gridLines}</g>
        </g>
    );
};

const BreakPoints = (props) => {
    const { scales, visualisation } = props;
    if (!visualisation.breakpoints) return null;

    const lines = visualisation.breakpoints.map((breakpoint) => {
        const y = scales.y(breakpoint.value);
        return (
            <line
                key={breakpoint.label}
                className={styles.breakpointLine}
                x1={scales.frame.left}
                x2={scales.frame.right}
                y1={y}
                y2={y}
                style={{
                    stroke: breakpoint.colour,
                }}
            />
        );
    });

    return <g>{lines}</g>;
};

const PolicyHighlight = (props) => {
    const { scales, policyInvokedData } = props;

    if (!policyInvokedData.length) return null;

    const rects = policyInvokedData.map((policyRange, policyIndex) => {
        const leftVal = scales.x.domain()[policyRange.start];
        const rightVal = scales.x.domain()[Math.min(policyRange.end, scales.x.domain().length - 1)];
        const policyLeft = scales.x(leftVal);
        const policyRight = scales.x(rightVal) + scales.x.bandwidth();
        const width = policyRight - policyLeft;

        return (
            <g key={policyRange.start}>
                <rect
                    className={styles.policyHighlight}
                    x={policyLeft}
                    y={scales.frame.top}
                    width={width}
                    height={scales.frame.height}
                />
                {policyIndex === 0 && (
                    <text className={styles.policyHighlightLabel} x={policyLeft} y={9}>
                        <tspan x={policyLeft} dy={0}>
                            Policy change
                        </tspan>
                    </text>
                )}
            </g>
        );
    });

    return <g>{rects}</g>;
};

const BarData = (props) => {
    const { data, scales, secondaryData, visualisation } = props;

    const { bidirectionalBars } = visualisation;

    const timespan = useSelector(selectTimespan);
    const hoveredDate = useSelector(selectHoveredDate);

    if (timespan >= TIMESPAN_USE_AREA && !visualisation.alwaysBars) return null;

    const barData = data.filter((d) => d.value !== null);

    let bars;

    if (bidirectionalBars) {
        const zeroY = scales.y(0);
        bars = barData.map((d, dataIndex) => {
            const left = scales.x(d.date);
            const width = scales.x.bandwidth();
            const { top, bottom } =
                d.value >= 0
                    ? {
                          top: scales.y(d.value),
                          bottom: zeroY,
                      }
                    : {
                          top: zeroY,
                          bottom: scales.y(d.value),
                      };
            const height = bottom - top;

            const hovered = d.date === hoveredDate;
            // No support for secondary bars here yet.

            return (
                <g key={d.date}>
                    <rect
                        className={styles.bar}
                        data-secondary={false}
                        data-hovered={hovered}
                        x={left}
                        y={top}
                        width={width}
                        height={height}
                        data-bidirectional
                        data-positive={d.value > 0}
                        data-negative={d.value < 0}
                    />
                </g>
            );
        });
    } else {
        bars = barData.map((d, dataIndex) => {
            const left = scales.x(d.date);
            const width = scales.x.bandwidth();
            const top = scales.y(d.value);
            const height = scales.frame.bottom - top;

            const hovered = d.date === hoveredDate;
            let secondaryBar = null;
            if (secondaryData) {
                const secondaryDatum = secondaryData[dataIndex];
                const secondaryTop = scales.y(d.value + secondaryDatum.value);
                const secondaryHeight = top - secondaryTop;
                secondaryBar = (
                    <rect
                        className={styles.bar}
                        data-secondary={true}
                        data-hovered={hovered}
                        key={d.date}
                        x={left}
                        y={secondaryTop}
                        width={width}
                        height={secondaryHeight}
                    />
                );
            }

            return (
                <g key={d.date}>
                    <rect
                        className={styles.bar}
                        data-secondary={false}
                        data-hovered={hovered}
                        x={left}
                        y={top}
                        width={width}
                        height={height}
                    />
                    {secondaryBar}
                </g>
            );
        });
    }

    return <g>{bars}</g>;
};

const HistoricalDataLines = (props) => {
    const { historicalData, scales, secondaryHistoricalData } = props;

    const hoveredDate = useSelector(selectHoveredDate);

    const basePath = React.useMemo(() => {
        if (!historicalData) return null;
        const gen = line()
            .x((d) => scales.x(d.date))
            .y((d) => scales.y(d.value))
            .defined((d) => d.value !== null && !isNaN(d.value) && d.date >= scales.x.domain()[0]);

        return gen(historicalData);
    }, [historicalData, scales]);

    const secondaryPath = React.useMemo(() => {
        if (!secondaryHistoricalData) return;
        const gen = line()
            .x((d) => scales.x(d.date))
            .y((d, i) => scales.y(historicalData[i].value + d.value))
            .defined(
                (d, i) =>
                    d.value !== null &&
                    historicalData[i].value !== null &&
                    !isNaN(d.value) &&
                    !isNaN(historicalData[i].value) &&
                    d.date >= scales.x.domain()[0] &&
                    historicalData[i].date >= scales.x.domain()[0]
            );

        return gen(secondaryHistoricalData);
    }, [historicalData, scales, secondaryHistoricalData]);

    const dotRadius = 2;
    const baseDotDatum = hoveredDate && historicalData.find((d) => d.date === hoveredDate);

    const baseDot = baseDotDatum && (
        <circle
            className={styles.areaDots}
            cx={scales.x(baseDotDatum.date)}
            cy={scales.y(baseDotDatum.value)}
            r={dotRadius}
        />
    );

    const secDotDatum =
        hoveredDate && secondaryHistoricalData && secondaryHistoricalData.find((d) => d.date === hoveredDate);
    const secondaryDot = secDotDatum && (
        <circle
            className={styles.areaDots}
            cx={scales.x(secDotDatum.date)}
            cy={scales.y(baseDotDatum.value + secDotDatum.value)}
            r={dotRadius}
        />
    );

    return (
        <g>
            <path className={styles.historyLine} d={basePath} />
            {secondaryPath && <path className={styles.historyLine} data-secondary d={secondaryPath} />}
            {baseDot}
            {secondaryDot}
        </g>
    );
};

const AreaData = (props) => {
    const { data, scales, secondaryData, visualisation } = props;

    const timespan = useSelector(selectTimespan);
    const hoveredDate = useSelector(selectHoveredDate);

    const basePath = React.useMemo(() => {
        const gen = area()
            .x((d) => scales.x(d.date))
            .y0(scales.frame.bottom)
            .y1((d) => scales.y(d.value))
            .defined((d) => d.value !== null && !isNaN(d.value));

        return gen(data);
    }, [data, scales]);

    const secondaryPath = React.useMemo(() => {
        if (!secondaryData) return;
        const gen = area()
            .x((d) => scales.x(d.date))
            .y0((d, i) => scales.y(data[i].value))
            .y1((d, i) => scales.y(data[i].value + d.value))
            .defined((d, i) => d.value !== null && data[i].value !== null && !isNaN(d.value) && !isNaN(data[i].value));

        return gen(secondaryData);
    }, [data, scales, secondaryData]);

    if (visualisation.alwaysBars) return null;

    if (timespan < TIMESPAN_USE_AREA) return null;

    const dotRadius = 2;
    const baseDotDatum = hoveredDate && data.find((d) => d.date === hoveredDate);

    const baseDot = baseDotDatum && (
        <circle
            className={styles.areaDots}
            cx={scales.x(baseDotDatum.date)}
            cy={scales.y(baseDotDatum.value)}
            r={dotRadius}
        />
    );

    const secDotDatum = hoveredDate && secondaryData && secondaryData.find((d) => d.date === hoveredDate);
    const secondaryDot = secDotDatum && (
        <circle
            className={styles.areaDots}
            cx={scales.x(secDotDatum.date)}
            cy={scales.y(baseDotDatum.value + secDotDatum.value)}
            r={dotRadius}
        />
    );

    const hoverRect = hoveredDate && (
        <rect
            className={styles.areaHighlightBar}
            x={scales.x(hoveredDate) - scales.x.bandwidth() / 2}
            y={scales.frame.top}
            width={scales.x.bandwidth()}
            height={scales.frame.height}
        />
    );

    return (
        <g>
            <path className={styles.areaData} d={basePath} />
            {secondaryPath && <path className={styles.areaData} data-secondary d={secondaryPath} />}
            {baseDot}
            {secondaryDot}
            {hoverRect}
        </g>
    );
};

const getMousePosition = (e) => {
    const bbox = e.target.getBoundingClientRect();
    return {
        x: e.clientX - bbox.x,
        y: e.clientY - bbox.y,
    };
};

const HoverOverlay = (props) => {
    const { data, scales } = props;

    const dispatch = useDispatch();

    const handleMouseMove = React.useCallback(
        (e) => {
            const { x } = getMousePosition(e);

            const paddingOuter = scales.x(data[0].date) - scales.x.range()[0];
            const eachBand = scales.x.step();
            const index = Math.round((x - paddingOuter - scales.x.bandwidth() / 2) / eachBand);
            const dateAtMouse = data[index];

            dispatch(setHoveredDate(dateAtMouse ? dateAtMouse.date : null));
        },
        [dispatch, data, scales]
    );

    const handleMouseLeave = React.useCallback(() => {
        dispatch(setHoveredDate(null));
    }, [dispatch]);

    return (
        <g>
            <rect
                className={styles.hoverOverlay}
                x={scales.frame.left}
                y={scales.frame.top}
                width={scales.frame.width}
                height={scales.frame.height}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
            />
        </g>
    );
};

export default BarChart;
