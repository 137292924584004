export const HISTORY_LENGTH = 25;

const TIMESPANS = [
    {
        label: "2 weeks",
        value: 14,
    },
    {
        label: "1 month",
        value: 30,
    },
    {
        label: "3 months",
        value: 90,
    },
    {
        label: "6 months",
        value: 180,
    },
    {
        label: "1 year",
        value: 365,
    },
    {
        label: "2 year",
        value: 365 * 2,
    },
    {
        label: "3 year",
        value: 365 * 3,
    },
    {
        label: "4 year",
        value: 365 * 4,
    },
    {
        label: "5 year",
        value: 365 * 5,
    },
];

export default TIMESPANS;
