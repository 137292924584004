import { createSlice } from "@reduxjs/toolkit";

// -------------------------------------------------------------
// Slice
export const slice = createSlice({
    name: "hover",
    initialState: {
        hoveredDate: null
    },
    reducers: {
        setHoveredDate: (state, action) => {
            state.hoveredDate = action.payload;
        }
    }
});

export const { setHoveredDate } = slice.actions;

// -------------------------------------------------------------
// Selectors
export const selectHoveredDate = state => state.hover.hoveredDate;

export default slice.reducer;
