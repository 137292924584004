import { combineReducers } from "@reduxjs/toolkit";
import data from "./data.slice";
import settings from "./settings.slice";
import hover from "./hover.slice";

const rootReducer = combineReducers({
    data,
    settings,
    hover
});

export default rootReducer;
