const POLICY_TRIGGERS = [
    {
        label: "Active Cases",
        value: "active_cases",
        defaultThresholds: [300, 700],
    },
    {
        label: "Newly Infected",
        value: "newly_infected",
        defaultThresholds: [20, 100],
    },
];

export default POLICY_TRIGGERS;
