const LOCATIONS = [
   {
      label: "Australia",
      value: "au",
      population: 25499881,
      icu_beds_supply: 2229
   },
   {
      label: "NSW",
      value: "aunsw",
      population: 8275674,
      icu_beds_supply: 874
   },
   //  {
   //      label: "National",
   //      value: "national",
   //      population: 25868818,
   //      // These are temporary!
   //      deaths: 21,
   //      historicalDataFilename: "placeholder-historical-au.csv",
   //  },
   {
      label: "Queensland",
      value: "auqld",
      population: 5071000,
      icu_beds_supply: 413
   },
   {
      label: "ACT",
      value: "auact",
      population: 438275,
      icu_beds_supply: 44
   },
   {
      label: "Victoria",
      value: "auvic",
      population: 6760752,
      icu_beds_supply: 476
   },
   {
      label: "Western Australia",
      value: "auwa",
      population: 2655657,
      icu_beds_supply: 162
   },
   {
      label: "South Australia",
      value: "ausa",
      population: 1760207,
      icu_beds_supply: 188
   },
   {
      label: "Tasmania",
      value: "autas",
      population: 535855,
      icu_beds_supply: 50
   },
   {
      label: "Northern Territory",
      value: "aunt",
      population: 254322,
      icu_beds_supply: 22
   },
   {
      label: "Singapore",
      value: "singapore",
      population: 4026209,
      icu_beds_supply: 235
   },
   {
      label: "United States",
      value: "us",
      population: 331002646,
      icu_beds_supply: 74289
   },
   {
      label: "Canada",
      value: "ca",
      population: 37742157,
      icu_beds_supply: 800
   },
   {
      label: "United Kingdom",
      value: "gb",
      population: 67886004,
      icu_beds_supply: 800
   },
   {
      label: "Philippines",
      value: "ph",
      population: 109581085,
      icu_beds_supply: 2081
   },
   {
      label: "Italy",
      value: "it",
      population: 60461828,
      icu_beds_supply: 2081
   }
];

export default LOCATIONS;
