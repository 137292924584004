import BarChart from "../components/bar-chart/bar-chart";
import format from "../modules/format";
import COLOURS from "../style/colours";

const VISUALISATION_CONFIG = {
    activeCases: {
        id: "active_cases",
        secondaryData: "newly_infected",
        label: "Active Cases",
        visualisation: BarChart,
        formatAxis: format.si(1),
        formatFactoid: format.commas(0),
        breakpoints: null,
    },
    recovered: {
        id: "tot_recovered",
        label: "Est. Herd Immunity",
        visualisation: BarChart,
        formatAxis: format.percent(1),
        formatFactoid: format.percent(2),
        breakpoints: null,
        calculateRowValue: (data, rowIndex, settings) => {
            const now = data["tot_recovered"][rowIndex];
            return now / settings.population;
        },
    },
    bedsCritical: {
        id: "required_icu_beds",
        label: "Hospitalized (Critical ICU)",
        visualisation: BarChart,
        formatAxis: format.si(0),
        formatFactoid: format.commas(0),
        breakpoints: [{ label: "ICU Beds", value: 874, colour: COLOURS.red }],
    },
    hospitalizedNonCritical: {
        id: "tot_hosp_non_crit",
        label: "Hospitalized (Non-critical)",
        visualisation: BarChart,
        formatAxis: format.si(0),
        formatFactoid: format.commas(0),
        breakpoints: null,
    },
    rateOfChange: {
        id: "rate-of-change",
        calculateRowValue: (data, rowIndex) => {
            if (rowIndex === 0) return 0;
            const now = data["active_cases"][rowIndex];
            const last = data["active_cases"][rowIndex - 1];
            if (now === 0 || last === 0) return 0;
            return now / last - 1;
        },
        label: "Rate of change",
        visualisation: BarChart,
        alwaysBars: true,
        bidirectionalBars: true,
        formatAxis: format.percent(2),
        formatFactoid: format.percent(2),
        breakpoints: null,
    },
};

export default VISUALISATION_CONFIG;
