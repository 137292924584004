const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
export const formatSI = decimals => num => {
    const tier = (Math.log10(num) / 3) | 0;
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);
    const scaled = num / scale;

    if (decimals === undefined) {
        return scaled + suffix;
    }
    return scaled.toFixed(decimals) + suffix;
};

export const formatCommas = (numDecimals = 0) => num =>
    num.toLocaleString(undefined, { maximumFractionDigits: numDecimals });
export const formatDecimals = (numDecimals = 2) => num =>
    num.toLocaleString(undefined, { maximumFractionDigits: numDecimals });
export const formatPolicyActive = v => (v === 0 ? "Inactive" : "Active");
export const formatPercent = (numDecimals = 0) => num =>
    (num * 100).toLocaleString(undefined, { maximumFractionDigits: numDecimals }) + "%";

const format = {
    commas: formatCommas,
    decimals: formatDecimals,
    policyActive: formatPolicyActive,
    percent: formatPercent,
    si: formatSI
};

export default format;
