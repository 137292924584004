import React from "react";
import styles from "./settings-panel.module.scss";
import { useSelector, useDispatch } from "react-redux";
import {
    selectTimespan,
    setTimespan,
    selectLocation,
    setLocation,
    selectDefaultPolicy,
    selectTriggerPolicy,
    setDefaultPolicy,
    setTriggerPolicy,
    selectLatentCases,
    setLatentCases,
    setPolicyTriggerCondition,
    setPolicyTriggerLower,
    setPolicyTriggerUpper,
    selectPolicyTriggerCondition,
    selectPolicyTriggerLower,
    selectPolicyTriggerUpper,
    selectPriorR0,
    setPriorR0,
} from "../../slices/settings.slice";
import Select from "react-select";
import TIMESPANS from "../../config/timespan-config";
import LOCATIONS from "../../config/location-config";
import POLICIES from "../../config/policy-config";
import FaethmLogo from "../../assets/img/faethm-logo.png";
import POLICY_TRIGGERS from "../../config/policy-trigger-config";
import { Auth } from 'aws-amplify';

const SettingsPanel = () => {
    const dispatch = useDispatch();
    const currentTimespan = useSelector(selectTimespan);
    const currentLocation = useSelector(selectLocation);
    const policyTriggerCondition = useSelector(selectPolicyTriggerCondition);
    const policyTriggerLower = useSelector(selectPolicyTriggerLower);
    const policyTriggerUpper = useSelector(selectPolicyTriggerUpper);
    const defaultPolicy = useSelector(selectDefaultPolicy);
    const triggerPolicy = useSelector(selectTriggerPolicy);
    const latentCases = useSelector(selectLatentCases);
    const priorR0 = useSelector(selectPriorR0);

    const handleTimespanChange = React.useCallback(
        (timespan) => {
            dispatch(setTimespan(timespan.value));
        },
        [dispatch]
    );
    const handleLocationChange = React.useCallback(
        (location) => {
            dispatch(setLocation(location.value));
        },
        [dispatch]
    );

    const handlePolicyTriggerConditionChange = React.useCallback(
        (policy) => {
            dispatch(setPolicyTriggerCondition(policy.value));
        },
        [dispatch]
    );
    const handlePolicyTriggerLowerChange = React.useCallback(
        (value) => {
            dispatch(setPolicyTriggerLower(value));
        },
        [dispatch]
    );
    const handlePolicyTriggerUpperChange = React.useCallback(
        (value) => {
            dispatch(setPolicyTriggerUpper(value));
        },
        [dispatch]
    );
    const handleDefaultPolicyChange = React.useCallback(
        (policy) => {
            dispatch(setDefaultPolicy(policy.value));
        },
        [dispatch]
    );
    const handleTriggerPolicyChange = React.useCallback(
        (policy) => {
            dispatch(setTriggerPolicy(policy.value));
        },
        [dispatch]
    );

    const handleLatentCasesChange = React.useCallback(
        (value) => {
            dispatch(setLatentCases(value));
        },
        [dispatch]
    );
    const handlePriorR0Change = React.useCallback(
        (value) => {
            dispatch(setPriorR0(value));
        },
        [dispatch]
    );

    const [availableLocations, setAvailableLocations] = React.useState([]);
    React.useEffect(() => {
        async function getLocations() {
            const user =  await Auth.currentAuthenticatedUser();
            // the array of groups that the user belongs to
            const groups = user.signInUserSession.accessToken.payload["cognito:groups"] || [];
            const available = LOCATIONS.filter(loc => groups.indexOf(loc.value) >= 0)
            setAvailableLocations(available);
            if(available.length > 0){
                handleLocationChange(available[0]);
            }
        }
        getLocations();
        
    }, [handleLocationChange]);

    return (
        <div className={styles.settingsPanel}>
            <img className={styles.logo} src={FaethmLogo} alt="Faethm Logo" />
            <div className={styles.settingsControls}>
                <div className={styles.settingsControlItem}>
                    <label className={styles.settingsControlLabel}>Location</label>
                    <div className={styles.dropdown}>
                        <Select
                            options={availableLocations}
                            value={availableLocations.find((d) => d.value === currentLocation)}
                            onChange={handleLocationChange}
                            isSearchable={true}
                        />
                    </div>
                </div>

                <div className={styles.settingsControlItem}>
                    <label className={styles.settingsControlLabel}>Trigger Condition</label>
                    <div className={styles.dropdown}>
                        <Select
                            options={POLICY_TRIGGERS}
                            value={POLICY_TRIGGERS.find((d) => d.value === policyTriggerCondition)}
                            onChange={handlePolicyTriggerConditionChange}
                            isSearchable={false}
                        />
                    </div>
                </div>

                <div className={styles.settingsControlItem}>
                    <label className={styles.settingsControlLabel}>Trigger policy at:</label>
                    <NumberField value={policyTriggerUpper} onChange={handlePolicyTriggerUpperChange} />
                </div>
                <div className={styles.settingsControlItem}>
                    <label className={styles.settingsControlLabel}>Return to default at:</label>
                    <NumberField value={policyTriggerLower} onChange={handlePolicyTriggerLowerChange} />
                </div>
                <div className={styles.settingsControlItem}>
                    <label className={styles.settingsControlLabel}>Prior R0</label>
                    <NumberField min={1} value={priorR0} onChange={handlePriorR0Change} />
                </div>

                <div className={styles.settingsControlItem}>
                    <label className={styles.settingsControlLabel}>Default Policy</label>
                    <PolicySelector value={defaultPolicy} onChange={handleDefaultPolicyChange} />
                </div>
                <div className={styles.settingsControlItem}>
                    <label className={styles.settingsControlLabel}>Trigger Policy</label>
                    <PolicySelector value={triggerPolicy} onChange={handleTriggerPolicyChange} />
                </div>

                <div className={styles.settingsControlItem}>
                    <label className={styles.settingsControlLabel}>Latent Cases in Society</label>
                    <Toggle value={latentCases} onChange={handleLatentCasesChange} />
                </div>

                <div className={styles.settingsControlItem}>
                    <label className={styles.settingsControlLabel}>Timespan</label>
                    <div className={styles.dropdown}>
                        <Select
                            options={TIMESPANS}
                            value={TIMESPANS.find((d) => d.value === currentTimespan)}
                            onChange={handleTimespanChange}
                            isSearchable={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const PolicySelector = (props) => {
    const { value, onChange } = props;
    const policyItems = POLICIES.map((policy) => {
        const selected = value === policy.value;
        return (
            <div
                className={styles.policy}
                key={policy.value}
                data-selected={selected}
                onClick={() => onChange && onChange(policy)}
            >
                <span className={styles.policyLabel}>{policy.label}</span>
                {policy.description && <span className={styles.policyDescription}>{policy.description}</span>}
            </div>
        );
    });
    return <div className={styles.policySelector}>{policyItems}</div>;
};

const Toggle = (props) => {
    const { value, onChange } = props;

    return (
        <div className={styles.toggle} onClick={() => onChange && onChange(!value)} data-on={value}>
            <span className={styles.toggleLabel}>{value ? "On" : "Off"}</span>
            <div className={styles.toggleControl}>
                <div className={styles.toggleHandle} data-on={value} />
            </div>
        </div>
    );
};

const Check = (props) => (
    <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 512 512" {...props}>
        <path
            fill="currentColor"
            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
        />
    </svg>
);

const NumberField = (props) => {
    const { value, onChange, min, float } = props;
    const [editing, setEditing] = React.useState(false);
    const [editValue, setEditValue] = React.useState(value);

    React.useEffect(() => {
        setEditing(false);
    }, [value]);

    let disabled = false;

    if (isNaN(editValue)) {
        disabled = true;
    }
    if (min && parseFloat(editValue) <= min) {
        disabled = true;
    }

    const inner = editing ? (
        <div className={styles.numberFieldInputContainer}>
            <input
                className={styles.numberFieldInput}
                type="number"
                value={editValue}
                onChange={(v) => setEditValue(v.target.value)}
                min={min}
                step={float && 0.05}
            />
            <button
                className={styles.numberFieldConfirmButton}
                disabled={disabled}
                onClick={() => {
                    onChange && onChange(parseFloat(editValue));
                    setEditing(false);
                }}
            >
                <Check />
            </button>
        </div>
    ) : (
        <div className={styles.numberFieldValue} onClick={() => setEditing(true)}>
            {value}
        </div>
    );

    return (
        <div className={styles.numberFieldOuter} data-editing={editing}>
            {inner}
        </div>
    );
};

export default SettingsPanel;
