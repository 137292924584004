import { createSlice, createSelector } from "@reduxjs/toolkit";
import LOCATIONS from "../config/location-config";
import POLICIES from "../config/policy-config";
import POLICY_TRIGGERS from "../config/policy-trigger-config";

// -------------------------------------------------------------
// Slice
export const slice = createSlice({
    name: "settings",
    initialState: {
        timespan: 180,
        location: LOCATIONS[0].value,
        latentCases: false,
        priorR0: 1.2,
        defaultPolicy: POLICIES[0].value,
        triggerPolicy: POLICIES[3].value,
        policyTriggerCondition: POLICY_TRIGGERS[1].value,
        policyTriggerLower: POLICY_TRIGGERS[1].defaultThresholds[0],
        policyTriggerUpper: POLICY_TRIGGERS[1].defaultThresholds[1],
    },
    reducers: {
        setTimespan: (state, action) => {
            state.timespan = action.payload;
        },
        setLocation: (state, action) => {
            state.location = action.payload;
        },
        setLatentCases: (state, action) => {
            state.latentCases = action.payload;
        },
        setPriorR0: (state, action) => {
            state.priorR0 = action.payload;
        },
        setDefaultPolicy: (state, action) => {
            state.defaultPolicy = action.payload;
        },
        setTriggerPolicy: (state, action) => {
            state.triggerPolicy = action.payload;
        },
        setPolicyTriggerCondition: (state, action) => {
            state.policyTriggerCondition = action.payload;
        },
        setPolicyTriggerLower: (state, action) => {
            state.policyTriggerLower = action.payload;
        },
        setPolicyTriggerUpper: (state, action) => {
            state.policyTriggerUpper = action.payload;
        },
    },
});

export const {
    setTimespan,
    setLocation,
    setScenario,
    setLatentCases,
    setDefaultPolicy,
    setTriggerPolicy,
    setPolicyTriggerCondition,
    setPolicyTriggerLower,
    setPolicyTriggerUpper,
    setPriorR0,
} = slice.actions;

// -------------------------------------------------------------
// Selectors
export const selectTimespan = (state) => state.settings.timespan;
export const selectLocation = (state) => state.settings.location;
export const selectScenario = (state) => state.settings.scenario;
export const selectPolicyRange = (state) => state.settings.policyRange;
export const selectLatentCases = (state) => state.settings.latentCases;
export const selectPriorR0 = (state) => state.settings.priorR0;
export const selectDefaultPolicy = (state) => state.settings.defaultPolicy;
export const selectTriggerPolicy = (state) => state.settings.triggerPolicy;
export const selectPolicyTriggerCondition = (state) => state.settings.policyTriggerCondition;
export const selectPolicyTriggerLower = (state) => state.settings.policyTriggerLower;
export const selectPolicyTriggerUpper = (state) => state.settings.policyTriggerUpper;

export const selectDataSettings = createSelector(
    selectLocation,
    selectPolicyTriggerCondition,
    selectPolicyTriggerLower,
    selectPolicyTriggerUpper,
    selectDefaultPolicy,
    selectTriggerPolicy,
    selectLatentCases,
    selectPriorR0,
    selectTimespan,
    (
        locationId,
        policyTriggerCondition,
        policyTriggerLower,
        policyTriggerUpper,
        defaultPolicyId,
        triggerPolicyId,
        latentCases,
        priorR0,
        timespan
    ) => {
        const locationSettings = LOCATIONS.find((d) => d.value === locationId);
        // TODO: use r0 vals directly in state?
        const defaultPolicySettings = POLICIES.find((d) => d.value === defaultPolicyId);
        const triggerPolicySettings = POLICIES.find((d) => d.value === triggerPolicyId);

        // TODO: need to get starting value from somewhere else! Maybe fetch inside data slice?

        const settings = {
            location_key: locationSettings.value,
            icu_beds_supply: locationSettings.icu_beds_supply,
            population: locationSettings.population,
            periods: timespan,

            // Trigger settings
            policy_trigger: policyTriggerCondition,
            policy_upper_threshold: policyTriggerUpper,
            policy_lower_threshold: policyTriggerLower,
            policy_lag: 2,

            // Policy R0's
            starting_r0: defaultPolicySettings.r0,
            policy_r0: triggerPolicySettings.r0,
            prior_r0: priorR0,

            // These remain default.
            close_contact_coefficient: null,
            close_contact_ratio: null,
            crit_req_icu: null,

            // These are temporary!
            latentCases,
        };

        return settings;
    }
);

export default slice.reducer;
