export const grey1 = "#20232b";
export const grey2 = "#2b2e37";
export const grey3 = "#37404a";

export const grey6 = "#8c95a2";
export const grey7 = "#9fa0a4";
export const grey8 = "#e7e9ec";

export const cyan = "#00d2c3";
export const cyan2 = "#6bb4af";

export const red = "#e6480e";

export const pri = cyan;

const COLOURS = {
    grey1,
    grey2,
    grey3,
    grey6,
    grey7,
    grey8,
    cyan,
    cyan2,
    red,
    pri
};

export default COLOURS;
