import React from "react";
import styles from "./vis-factoid.module.scss";
import { format as formatDate } from "date-fns";

const VisFactoid = (props) => {
    const { visualisation, data, hoveredDate, secondaryData } = props;

    const maxDate = React.useMemo(() => {
        let max = null;
        data.filter((d) => d.value !== null).forEach((d) => {
            if (d.value && (!max || max.value === null || d.value > max.value)) {
                max = d;
            }
        });
        return max ? max.date : null;
    }, [data]);

    const datum = React.useMemo(() => {
        if (hoveredDate) {
            return data.find((d) => d.date === hoveredDate);
        }
        if (maxDate) {
            return data.find((d) => d.date === maxDate);
        }
        return null;
    }, [data, hoveredDate, maxDate]);

    const secondaryDatum = React.useMemo(() => {
        if (!secondaryData) return null;
        if (hoveredDate) {
            return secondaryData.find((d) => d.date === hoveredDate);
        }
        if (maxDate) {
            return secondaryData.find((d) => d.date === maxDate);
        }
        return null;
    }, [secondaryData, hoveredDate, maxDate]);

    return (
        <div className={styles.visFactoid}>
            <div className={styles.visHeading}>{visualisation.label}</div>
            {datum && !isNaN(datum.value) && datum.value && (
                <div className={styles.visNumbers}>
                    <div className={styles.visValue}>
                        {visualisation.formatFactoid(datum.value)}
                        {secondaryDatum &&
                            ` (${secondaryDatum.value >= 0 && "+"}${visualisation.formatFactoid(
                                secondaryDatum.value
                            )})`}
                    </div>
                    <div className={styles.visDate}>
                        {datum.max ? "Maximum value occured on " : ""}
                        {formatDate(datum.date, "dd MMM")}
                    </div>
                </div>
            )}
        </div>
    );
};

export default VisFactoid;
