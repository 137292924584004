import React from "react";
import styles from "./app.module.scss";
import SettingsPanel from "./components/settings-panel/settings-panel";
import VisList from "./components/vis-list/vis-list";
import { useSelector, useDispatch } from "react-redux";
import { selectDataSettings } from "./slices/settings.slice";
import { selectLoading, updateData } from "./slices/data.slice";
import Loader from "./components/loader/loader";
import Amplify from 'aws-amplify';
import awsConfig from './aws-exports';
import { withAuthenticator, SignIn, Greetings, ConfirmSignIn, VerifyContact, ForgotPassword, RequireNewPassword } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';

Amplify.configure(awsConfig);

function App() {
    return (
        <div>
            <LoadingOverlay />
            <div className={styles.container}>
                <div className={styles.body}>
                    <SettingsPanel />
                    <VisList />
                </div>
            </div>
        </div>
    );
}

const LoadingOverlay = () => {
    const dispatch = useDispatch();
    const dataSettings = useSelector(selectDataSettings);
    const loading = useSelector(selectLoading);

    React.useEffect(() => {
        dispatch(updateData(dataSettings));
    }, [dispatch, dataSettings]);

    return (
        <div className={styles.loadingOverlay} data-visible={loading}>
            <Loader />
        </div>
    );
};

export default withAuthenticator(App, true, [
    <Greetings inGreeting="" />,
    <SignIn />,
    <ConfirmSignIn />,
    <VerifyContact />,
    <ForgotPassword />,
    <RequireNewPassword />
]);
